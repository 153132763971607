import Global from '@/mixins/global'

export default {
  mixins:[Global],
  data() {
    return {}
  },
  watch   : {
    widgetAnswer: {
      handler(answer, oldVal) {
        this.$set(this.widget, 'customContent', answer)
      },
      deep     : true,
      immediate: true,
    },
  },
  computed: {
    widgetAnswer() {
      if (this.widget.hasOwnProperty('answer')) {
        return this.widget.answer
      }
      else {
        this.$set(this.widget, 'answer', '')
        return this.widget.answer
      }
    },
    computedWidgetComponentName() {
      if (this.widget.componentName) {
        return this.widget.componentName
      }
      else {
        return '缺省标题'
      }
    },
    computedWidgetCustomContent: {
      get() {
        if (!this.widget.hasOwnProperty('customContent')) {
          this.$set(this.widget, 'customContent', '')
        }
        return this.widget.customContent
      },
      set(newValue) {

        if (!this.widget.hasOwnProperty('customContent')) {
          this.$set(this.widget, 'customContent', newValue)
        }
        this.widget.customContent = newValue
      },
    },
  },
  methods : {
    /**
     *
     * @param widget {Object}
     * @param key {String}
     * @param initialValue {any}
     */
    // registerProperty(widget, key, initialValue = '') {
    //   if (!widget.hasOwnProperty(key)) {
    //     this.$set(widget, key, initialValue)
    //   }
    // },
    registerProperty(widget, key, initialValue = '') {
      if (!widget.hasOwnProperty(key)) {
        // this.$set(widget, key, initialValue)
        // this.$set(widget, key, initialValue)
        widget[key] = initialValue
      }
    },
    getProvinceAndCityAndDistrict(list, separator) {
      if (Object.prototype.toString.call(list) == '[object Array]') {
        if (list.length == 3) {
          let provinceValue = list[0]
          let cityValue     = list[1]
          let districtValue = list[2]
          let province      = window.pcaOptions.find(item => item.value == provinceValue)
          if (!province) {return '未知'}
          let city = province.children.find(item => item.value == cityValue)
          if (!city) {return `${province.label}/未知`}
          let district = city.children.find(item => item.value == districtValue)
          if (!district) {
            return `${province.label}/${city.label}/未知`
          }
          else {
            return `${province.label}/${city.label}/${district.label}`
          }
        }
        else {
          return ''
        }
      }
      else {
        return ''
      }
    },
  },
}
