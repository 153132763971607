export default {

  /** methods */
  methods: {
    handleResponse(res, cb, message, error) {
      if (res.data.code == 0) {
        // let { results, totalCount, pageNo } = res.data.data
        // this.tableData                      = results
        // this.totalCount                     = totalCount
        cb && cb(res.data.data)
        if (message) {this.$message({ showClose: true, message: message, type: 'success' })}
      }
      else {
        error && error()
        this.$message({ showClose: true, message: res.data.msg, type: 'error' })
      }
    },

    handleResponseExportExcel(res, fileName) {
      const content = res.data //arraybuffer类型数据
      // console.log(content)
      const resBlob = new Blob([content])
      const reader  = new FileReader()
      reader.readAsText(resBlob, 'utf-8')
      // reader.readAsText(resBlob)
      reader.onload = e => {
        const result = e.target.result
        if (result && result.substr(0, 1) == '{') {
          var r = JSON.parse(result)
          this.$message({ showClose: true, message: r.msg, type: 'error' })
        }
        else {
          if (res.status == '200') {
            let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
            if (null != blob && blob.size > 0) {
              const aLink = document.createElement('a')
              aLink.href  = URL.createObjectURL(blob)
              if (fileName) {
                aLink.setAttribute('download', fileName + '.xls') // 设置下载文件名称
                // aLink.setAttribute('download', '优惠券用户模板' + '.xls') // 设置下载文件名称
              }else{
                let contentDisposition = res.headers['content-disposition']
                if(contentDisposition){
                  let f = decodeURI(contentDisposition.substring(contentDisposition.indexOf('filename=') + 'filename='.length))
                  aLink.setAttribute('download', f) // 设置下载文件名称
                }
              }
              aLink.click()
            }
            else {
              this.$message({ showClose: true, message: '没有查询到数据哦', type: 'error' })
            }
          }
          else {
            this.$message({ showClose: true, message: '无数据', type: 'error' })
          }
        }
      }
    },
    downloadFileWithURL(url) {
      var a     = document.createElement('a') // 生成一个a元素
      var event = new MouseEvent('click') // 创建一个单击事件
      // a.download = name || 'photo' // 设置图片名称
      a.href = url // 将生成的URL设置为a.href属性
      a.dispatchEvent(event) // 触发a的单击事件
    },

    urlToBlob(url) {
      return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest()
        request.open('GET', url, true)
        request.responseType = 'blob'
        request.onload       = () => {
          resolve(request.response)
        }
        request.onerror      = (e) => {
          reject(e.value)
        }
        request.send()
      })
    },

    // normalizeImageUrl() {
    //   return  (url) => {
    //     if (url) {
    //       // 有
    //       if (url.startsWith('http') || url.startsWith('https') || url.startsWith('blob')) {
    //         return url
    //       }
    //       else {
    //         if (url.startsWith('/')) {
    //           return process.env.VUE_APP_IMAGE_RESOURCE_PREFIX_URL + url
    //         }
    //         else {
    //           return process.env.VUE_APP_IMAGE_RESOURCE_PREFIX_URL + '/' + url
    //         }
    //       }
    //     }
    //     else {
    //       // 没有
    //       return url
    //     }
    //   }
    // },

    getProvinceAndCityAndDistrict(list, separator) {
      if (Object.prototype.toString.call(list) == '[object Array]') {
        if (list.length == 3) {
          let provinceValue = list[0]
          let cityValue     = list[1]
          let districtValue = list[2]
          console.log('【pcaMixin:40】',`provinceValue = `,provinceValue);
          console.log('【pcaMixin:41】',`cityValue = `,cityValue);
          console.log('【pcaMixin:42】',`districtValue = `,districtValue);
          let province      = window.pcaOptions.find(item => item.value == provinceValue)
          if (!province) {return '未知'}
          let city = province.children.find(item => item.value == cityValue)
          if (!city) {return `${province.label}/未知`}
          let district = city.children.find(item => item.value == districtValue)
          if (!district) {
            return `${province.label}/${city.label}/未知`
          }
          else {
            return `${province.label}/${city.label}/${district.label}`
          }
        }
        else {
          return ''
        }
      }
      else {
        return ''
      }
    },

    confirmMessage(message, onFulfilled, onRejected = () => {}) {
      this.$confirm(message, '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(onFulfilled).catch(onRejected)
    },

    successMessage(message) {
      this.$message({ showClose: true, message: message, type: 'success' })
    },
    warningMessage(message) {
      this.$message({ showClose: true, message: message, type: 'warning' })
    },
    errorMessage(message) {
      this.$message({ showClose: true, message: message, type: 'error' })
    },
    normalMessage(message) {
      this.$message({ showClose: true, message: message })
    },

    // async cutImage(uploadList) {
    //   let list = uploadList.filter(item => {return item.status === 'ready'})
    //   console.log(list)
    //   for (let listElement of list) {
    //     let blobList = await this.$rawVideoGetBlobList(URL.createObjectURL(listElement.raw))
    //     listElement.coverPictureList = []
    //     for (let blob of blobList) {
    //       const formData = new FormData()
    //       formData.append('file', blob, new Date().getTime() + '.jpg')
    //       const res = await this.shuziApi.lookContentUploadVideoImg(formData)
    //
    //       if (res.data.code == 0) {
    //         listElement.coverPictureList.push(res.data.data.url)
    //       }
    //       else {
    //         this.$message({ showClose: true, message: res.data.msg, type: 'error' })
    //       }
    //     }
    //     console.log(blobList)
    //   }
    //   console.log(list)
    //   // for (let i = 0; i < list.length; ++i) {
    //   //   let listElement = list[i]
    //   // }
    //   //
    //   // let results = await Promise.all([
    //   //     this.foo(URL.createObjectURL(list[0].raw)),
    //   //     this.foo(URL.createObjectURL(list[1].raw)),
    //   //     this.foo(URL.createObjectURL(list[2].raw)),
    //   //     this.foo(URL.createObjectURL(list[3].raw)),
    //   // ])
    //   // console.log(results);
    // },

    // rawVideoGetBlobList(videoUrl, count = 4) {
    //   return new Promise((resolve, reject) => {
    //
    //     const videoTag  = document.createElement('video')
    //     const canvasTag = document.createElement('canvas')
    //
    //     videoTag.id  = 'videoTag'
    //     canvasTag.id = 'canvasTag'
    //
    //     videoTag.setAttribute('crossOrigin', 'anonymous')//处理跨域
    //     videoTag.setAttribute('width', 560)
    //     videoTag.setAttribute('height', 315)
    //     videoTag.setAttribute('controls', 'controls')
    //     videoTag.setAttribute('preload', 'metadata')
    //
    //     const ctx = canvasTag.getContext('2d')
    //
    //     let times     = []
    //     let imageList = []
    //
    //     videoTag.addEventListener('loadedmetadata', () => {
    //       canvasTag.width  = videoTag.videoWidth
    //       canvasTag.height = videoTag.videoHeight
    //       // 首针
    //       times            = [0.01]
    //       let number       = count - 2
    //       for (let i = 1; i <= number; i++) {
    //         times.push(videoTag.duration / (number + 1) * i)
    //       }
    //       // 尾针
    //       times.push(videoTag.duration)
    //     })
    //
    //     videoTag.addEventListener('canplay', () => {
    //       if (videoTag.currentTime === 0) {
    //         if (times.length > 0) {videoTag.currentTime = times.shift()}
    //       }
    //       canvasTag.style.display = 'inline'
    //       ctx.drawImage(videoTag, 0, 0, videoTag.videoWidth, videoTag.videoHeight)
    //       // const dataURL = canvasTag.toDataURL('image/jpeg') //转换为base64
    //       canvasTag.toBlob((blob) => {
    //         if (videoTag.currentTime !== 0) {
    //           imageList.push(blob)
    //           if (times.length > 0) {
    //             videoTag.currentTime = times.shift()
    //           }
    //           else {
    //             resolve(imageList)
    //           }
    //         }
    //       }, 'image/jpeg')
    //     })
    //
    //     videoTag.src = videoUrl
    //   })
    // },
    // rawVideoGetBase64List(videoUrl, count = 4) {
    //   return new Promise((resolve, reject) => {
    //
    //     const videoTag  = document.createElement('video')
    //     const canvasTag = document.createElement('canvas')
    //
    //     videoTag.id  = 'videoTag'
    //     canvasTag.id = 'canvasTag'
    //
    //     videoTag.setAttribute('crossOrigin', 'anonymous')//处理跨域
    //     videoTag.setAttribute('width', 560)
    //     videoTag.setAttribute('height', 315)
    //     videoTag.setAttribute('controls', 'controls')
    //     videoTag.setAttribute('preload', 'metadata')
    //
    //     const ctx = canvasTag.getContext('2d')
    //
    //     let times     = []
    //     let imageList = []
    //
    //     videoTag.addEventListener('loadedmetadata', () => {
    //       canvasTag.width  = videoTag.videoWidth
    //       canvasTag.height = videoTag.videoHeight
    //       // 首针
    //       times            = [0.01]
    //       let number       = count - 2
    //       for (let i = 1; i <= number; i++) {
    //         times.push(videoTag.duration / (number + 1) * i)
    //       }
    //       // 尾针
    //       times.push(videoTag.duration)
    //     })
    //
    //     videoTag.addEventListener('canplay', () => {
    //       if (videoTag.currentTime !== 0) {
    //         canvasTag.style.display = 'inline'
    //         ctx.drawImage(videoTag, 0, 0, videoTag.videoWidth, videoTag.videoHeight)
    //         const dataURL = canvasTag.toDataURL('image/jpeg') //转换为base64
    //         imageList.push(dataURL)
    //       }
    //       if (times.length > 0) {
    //         videoTag.currentTime = times.shift()
    //       }
    //       else {
    //         resolve(imageList)
    //       }
    //     })
    //     videoTag.src = videoUrl
    //   })
    // },
  },
}
